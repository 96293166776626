import { clsx } from 'clsx'

import { storyblokStylesGetBorderClasses } from './StoryblokBorder/storyblokStylesGetBorderClasses'
import {
  type CmsStyleScreen,
  type StoryblokStylesType,
} from './StoryblokStylesProps'

const getComputedClassesByBlokAndScreen = (
  styles: StoryblokStylesType,
  screen?: CmsStyleScreen,
): string => {
  if (!styles) {
    return ''
  }

  const computedClasses = styles
    .map((styleBlok) => {
      switch (styleBlok.component) {
        case 'Border': {
          return storyblokStylesGetBorderClasses(styleBlok, screen)
        }
        default: {
          return ''
        }
      }
    })
    .join(' ')

  return computedClasses
}

const storyblokStylesGetClasses = ({
  responsiveStyles,
  styles,
  stylesDesktop,
  stylesTablet,
}: {
  responsiveStyles?: boolean
  styles: StoryblokStylesType
  stylesDesktop?: StoryblokStylesType
  stylesTablet?: StoryblokStylesType
}): string => {
  const mobileFirstClasses = getComputedClassesByBlokAndScreen(styles)
  const tabletClasses = responsiveStyles
    ? getComputedClassesByBlokAndScreen(stylesTablet, 'tablet')
    : null
  const desktopClasses = responsiveStyles
    ? getComputedClassesByBlokAndScreen(stylesDesktop, 'desktop')
    : null

  return clsx(mobileFirstClasses, tabletClasses, desktopClasses)
}

export const useStoryblokStyles = ({
  responsiveStyles,
  styles,
  stylesDesktop,
  stylesTablet,
}: {
  responsiveStyles?: boolean
  styles: StoryblokStylesType
  stylesDesktop?: StoryblokStylesType
  stylesTablet?: StoryblokStylesType
}): { blokClasses: string } => {
  const blokClasses = storyblokStylesGetClasses({
    responsiveStyles,
    styles,
    stylesDesktop,
    stylesTablet,
  })

  return { blokClasses }
}
