import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokImageV2Type } from '../../model/storyblokTypes.generated'
import { useStoryblokStyles } from '../StoryblokStyles/useStoryblokStyles'

export const StoryblokImageV2: FC<
  StoryblokComponentProps<StoryblokImageV2Type>
> = ({ blok }) => {
  const {
    alt,
    fill,
    height,
    responsiveStyles,
    src,
    styles,
    stylesDesktop,
    stylesTablet,
    width,
  } = blok
  const { blokClasses } = useStoryblokStyles({
    responsiveStyles,
    styles,
    stylesDesktop,
    stylesTablet,
  })

  if (!fill && !(width && height)) {
    return null
  }

  const imageProps = {
    ...(!fill && height ? { height: Number(height) } : {}),
    ...(!fill && width ? { width: Number(width) } : {}),
    ...(fill ? { fill: Boolean(fill) } : {}),
  }

  return (
    src && (
      <Image
        className={clsx(fill && 'object-contain', blokClasses)}
        {...storyblokEditable(blok)}
        alt={alt ?? ''}
        src={src}
        {...imageProps}
        unoptimized
      />
    )
  )
}
