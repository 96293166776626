import { clsx } from 'clsx'

import { type StoryblokBorderType } from '../../../model/storyblokTypes.generated'
import { type CmsStyleScreen } from '../StoryblokStylesProps'

const getBorderRadiusDesktop = (
  borderRadius: StoryblokBorderType['borderRadius'],
): string => {
  return clsx(
    borderRadius === 'none' && 'desktop:rounded-none',
    borderRadius === 'small' && 'desktop:rounded-lg',
    borderRadius === 'medium' && 'desktop:rounded-2xl',
    borderRadius === 'large' && 'desktop:rounded-3xl',
    borderRadius === 'full' && 'desktop:rounded-full',
  )
}

const getBorderRadiusTablet = (
  borderRadius: StoryblokBorderType['borderRadius'],
): string => {
  return clsx(
    borderRadius === 'none' && 'tablet:rounded-none',
    borderRadius === 'small' && 'tablet:rounded-lg',
    borderRadius === 'medium' && 'tablet:rounded-2xl',
    borderRadius === 'large' && 'tablet:rounded-3xl',
    borderRadius === 'full' && 'tablet:rounded-full',
  )
}

const getBorderRadius = (
  borderRadius: StoryblokBorderType['borderRadius'],
): string => {
  return clsx(
    borderRadius === 'none' && 'rounded-none',
    borderRadius === 'small' && 'rounded-lg',
    borderRadius === 'medium' && 'rounded-2xl',
    borderRadius === 'large' && 'rounded-3xl',
    borderRadius === 'full' && 'rounded-full',
  )
}

const getBorderRadiusStyles = (
  borderRadius: StoryblokBorderType['borderRadius'],
  screen: CmsStyleScreen,
): string => {
  if (!borderRadius) {
    return ''
  }

  switch (screen) {
    case 'desktop': {
      return getBorderRadiusDesktop(borderRadius)
    }
    case 'tablet': {
      return getBorderRadiusTablet(borderRadius)
    }
    default: {
      return getBorderRadius(borderRadius)
    }
  }
}

export const storyblokStylesGetBorderClasses = (
  styleBlok: StoryblokBorderType,
  screen: CmsStyleScreen,
): string => {
  return clsx(getBorderRadiusStyles(styleBlok.borderRadius, screen))
}
